import { useEffect, useState } from "react";
import Close from "assets/svgs/icons/close.svg";
import Button from "../../atoms/Button";
import { classList } from "dynamic-class-list";
import { useSpring, animated } from "react-spring";
import styles from "./index.module.scss";
import { usePreventScroll } from "@react-aria/overlays";
import { sleep } from "../../../../utils/utils";

export interface ModalProps {
  showModal: boolean;
  title?: string;
  children: React.DetailedHTMLProps<React.HTMLAttributes<any>, any>;
  closeIcon?: React.DetailedHTMLProps<React.HTMLAttributes<any>, any>;
  onClose: () => void;
  preventOutsideClose?: boolean;
  style?: React.CSSProperties | undefined;
  className?: string | string[];
  showClose?: boolean;
  hideOverflow?: boolean;
}

const Modal = ({
  title,
  children,
  showModal,
  onClose,
  className = [],
  showClose = true,
  closeIcon,
  preventOutsideClose = false,
  hideOverflow = false,
  ...props
}: ModalProps) => {
  className = typeof className === "string" ? [className] : className;

  const [overlay, setOverlay] = useState(false);
  const [modal, setModal] = useState(false);
  const [showAll, setShowAll] = useState(false);
  // Prevent scrolling while the modal is open, and hide content
  // outside the modal from screen readers.
  // usePreventScroll({ isDisabled: !showAll });

  useEffect(() => {
    const closeOnEscapeKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onClose();
      }
    };

    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, [onClose]);

  useEffect(() => {
    if (showModal) {
      setShowAll(true);
      sleep(200).then(() => {
        setOverlay(true);
        sleep(100).then(() => {
          setModal(true);
        });
      });
    } else {
      setModal(false);

      sleep(100).then(() => {
        setOverlay(false);
        sleep(300).then(() => {
          setShowAll(false);
        });
      });
    }
  }, [showModal]);

  const modalAnimation = useSpring({
    config: {
      duration: 200
    },
    opacity: modal ? 1 : 0,
    transform: modal ? "translateY(0px)" : "translateY(34px)"
  });

  const overlayAnimation = useSpring({
    config: {
      duration: 100
    },
    opacity: overlay ? 1 : 0
  });

  return (
    <>
      {showAll && (
        <>
          <animated.div
            id='modal-overlay'
            onClick={preventOutsideClose ? () => {} : onClose}
            className={classList([styles.modal])}
            style={overlayAnimation}
          >
            <animated.div
              style={{
                ...modalAnimation,
                overflow: hideOverflow ? "hidden" : "unset"
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
              className={classList([styles["modal-content"], ...className])}
            >
              {showClose && (
                <Button
                  size='small'
                  label={closeIcon ? closeIcon : <Close />}
                  color='white'
                  onClick={onClose}
                  className={
                    closeIcon
                      ? styles["close-button-styleless"]
                      : styles["close-button"]
                  }
                />
              )}
              <div className={styles.container}>
                {title && <h2 className={styles.title}>{title}</h2>}

                {children}
              </div>
            </animated.div>
          </animated.div>
        </>
      )}
    </>
  );
};

export default Modal;
