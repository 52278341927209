import styles from "./index.module.scss";

export interface DropdownProps {
  showDropdown: boolean;
  children: React.DetailedHTMLProps<React.HTMLAttributes<any>, any>;
  className?: string | undefined;
}

const Dropdown = ({ children, showDropdown, className }: DropdownProps) => {
  return (
    <>
      {showDropdown && (
        <div className={`${styles.dropdown} ${className}`}>{children}</div>
      )}
    </>
  );
};

export default Dropdown;
