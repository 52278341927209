import api from "./axios";

export const authenticateUser = async (phone_number: string, captcha: string) => {
  const { data } = await api.post("customer-service-proxy/auth", { phone_number, captcha });
  return data;
};

export const verifyPin = async (phone_number: string, pin: string) => {
  const { data } = await api.post("customer-service-proxy/auth/verify", {
    phone_number,
    code: pin
  });
  return data;
};
