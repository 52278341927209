import NextImage, { ImageProps } from "next/image";

const Image: React.FC<ImageProps> = ({ src, alt, ...props }) => {
  return typeof src === "string" ? (
    <NextImage src={src} unoptimized quality={100} alt={alt} {...props} />
  ) : (
    <></>
  );
};

export default Image;
