import axios, { AxiosInstance } from "axios";
import Cookies from "js-cookie";

const api: AxiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
});

// Add request interceptor
api.interceptors.request.use(
  async (config) => {
    let token = Cookies.get("token") ?? null;

    if (!token && typeof window !== "undefined") {
      token = localStorage.getItem("token") ?? null;
      if (token) {
        Cookies.set("token", token);
      }
    }

    if (token && !config.headers["Authorization"]) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    config.headers["Content-Type"] = "application/json";
    config.headers["Accept"] = "application/json";

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default api;
