import { AxiosRequestConfig } from "axios";
import api from "./axios";

export const getVerificationBanks = async () => {
  const { data } = await api.get(`customer-service-proxy/wallet/banks`);
  return data;
};

export const getBanks = async () => {
  const { data } = await api.get(`customer-service-proxy/wallet/transfer/banks`);
  return data;
};

export const verifyWallet = async (payload: {
  nip: string;
  account: string;
}) => {
  const { data } = await api.post(`customer-service-proxy/wallet/verify`, payload);
  return data;
};

export const createWallet = async (pin: string) => {
  const { data } = await api.post(`customer-service-proxy/wallet`, { code: pin });
  return data;
};

export const getWalletData = async (token?: string) => {
  let config: AxiosRequestConfig | undefined;

  if (token) {
    config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
  }
  const { data } = await api.get(`customer-service-proxy/wallet`, config);
  return data;
};

export const verifyAccount = async (payload: {
  code: string;
  account: string;
}) => {
  const { data } = await api.get(
    `customer-service-proxy/wallet/transfer/resolve?account_number=${payload.account}&code=${payload.code}`
  );
  return data;
};

export const sendUpdateWalletOtp = async () => {
  const { data } = await api.post(`customer-service-proxy/wallet/transfer/send-verification`);
  return data;
};

export const updateWallet = async (payload: {
  code: string;
  account_number: string;
  verification_code: string;
}) => {
  const { data } = await api.patch(`customer-service-proxy/wallet/transfer/bank`, payload);
  return data;
};

export const fetchTransferFee = async (amount: string) => {
  const { data } = await api.get(`customer-service-proxy/wallet/transfer/fees/${amount}`);
  return data;
};

export const withdrawFunds = async (amount: number) => {
  const { data } = await api.post(`customer-service-proxy/wallet/transfer`, { amount });
  return data;
};

export const fetchTransactionsData = async (token?: string) => {
  let config: AxiosRequestConfig | undefined;

  if (token) {
    config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
  }
  const { data } = await api.get(`customer-service-proxy/wallet/transactions`, config);
  return data;
};
