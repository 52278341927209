/* eslint-disable @next/next/no-img-element */
import { FC, useEffect } from "react";
import Link from "next/link";
import styles from "./index.module.scss";
import VisaLogo from "assets/svgs/icons/visa.svg";
import MasterCardLogo from "assets/svgs/icons/master-card.svg";
import VerveLogo from "assets/svgs/icons/verve.svg";
import AbegLogo from "assets/svgs/icons/abeg.svg";
import ThePeer from "assets/svgs/icons/thepeer.svg";
import FacebookLogo from "assets/svgs/icons/facebook.svg";
import TwitterLogo from "assets/svgs/icons/twitter.svg";
import InstagramLogo from "assets/svgs/icons/instagram.svg";
import { BusinessDetails } from "api/home";
import dayjs from "dayjs";
import Cookies from "js-cookie";

const Footer: FC<BusinessDetails> = ({
  name,
  phone_number,
  facebook,
  instagram,
  twitter,
  foreground,
  color
}) => {
  useEffect(() => {
    document.documentElement.style.setProperty(
      "--brand-color-foreground",
      foreground
    );
    document.documentElement.style.setProperty("--brand-color", color);
  }, [foreground, color]);

  useEffect(() => {
    let token = Cookies.get("token") ?? null;

    if (!token) {
      token = localStorage.getItem("token") ?? null;
      if (token) {
        Cookies.set("token", token);
        location.reload();
      }
    }
  }, []);
  return (
    <footer className={styles.footer}>
      <div className={`${styles.container} `}>
        <section className='container'>
          <div className={styles.top}>
            <div className={styles.content}>
              <h2 className={styles.logo}>
                <Link href='/'>
                  <a>{name}</a>
                </Link>
              </h2>

              <div>
                <div className={styles["phone-number"]}>
                  <h5>Call Restaurant</h5>
                  <p>
                    <a href={`tel:${phone_number}`}>
                      {phone_number?.replace("+234", "0")}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.bottom}>
            <p className={styles.copyright}>
              Powered by{" "}
              <a
                href='http://thefusion.app'
                target='_blank'
                rel='noopener noreferrer'
              >
                © {dayjs().year()} Fusion Technology
              </a>
              . All rights reserved.
            </p>

            <div className={styles.ss}>
              <div className={styles.socials}>
                {facebook && (
                  <Link href=''>
                    <a>
                      <FacebookLogo />
                    </a>
                  </Link>
                )}

                {instagram && (
                  <Link href={`https://www.instagram.com/${instagram}/`}>
                    <a>
                      <InstagramLogo />
                    </a>
                  </Link>
                )}

                {twitter && (
                  <Link href={`https://twitter.com/${twitter}/`}>
                    <a>
                      <TwitterLogo />
                    </a>
                  </Link>
                )}
              </div>
            </div>
            <div className={styles.payments}>
              <AbegLogo />
              <MasterCardLogo />
              <VisaLogo />
              <VerveLogo />
              {/* <ThePeer /> */}
            </div>
          </div>
        </section>
      </div>
    </footer>
  );
};

export default Footer;
