import { AxiosError } from "axios";
import toast from "react-hot-toast";

interface ErrorData {
  data?: { message: string }[];
  message: string;
}

const showErrorToast = (error: AxiosError<ErrorData>) => {
  let error_message = "Something is not right, please try again.";
  const data: ErrorData | undefined = error.response?.data;

  if (data?.data) error_message = data?.data[0].message;
  else
    error_message =
      data?.message ?? "Something is not right, please try again.";

  return toast.error(error_message);
};

export default showErrorToast;
