import { DefaultSeo, DefaultSeoProps } from "next-seo";
import type { AppProps } from "next/app";
import { GlobalContextProvider } from "../context/GlobalContext";
import { useEffect } from "react";
import NavBar from "components/UI/molecules/NavBar";
import "../assets/scss/app.scss";
import { PusherProvider, PusherProviderProps } from "@harelpls/use-pusher";
import styles from "./_app.module.scss";
import Footer from "components/UI/molecules/Footer";
import NextNProgress from "nextjs-progressbar";
import { Toaster } from "react-hot-toast";
import ErrorIcon from "assets/svgs/icons/sad.svg";
import SuccessIcon from "assets/svgs/icons/happy.svg";
import Head from "next/head";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDGU_TuPfSytZ8ZudlvnRT0vMcx6bBaVh4",
  authDomain: "buyfood-store.firebaseapp.com",
  projectId: "buyfood-store",
  storageBucket: "buyfood-store.appspot.com",
  messagingSenderId: "17819662611",
  appId: "1:17819662611:web:086c4c61789b76741582e6",
  measurementId: "G-6DEVJJ8MH5"
};

initializeApp(firebaseConfig);

const MyApp = ({ Component, pageProps }: AppProps) => {
  const defaultSeo: DefaultSeoProps = {
    title: pageProps?.name,
    titleTemplate: `%s | Order meals from ${pageProps?.name}`
  };

  const pusherConfig: PusherProviderProps = {
    // required config props
    clientKey: "2719beacb67892898175",
    cluster: "eu"
  };

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--brand-color",
      pageProps?.color
    );
  }, [pageProps]);

  return (
    <PusherProvider {...pusherConfig}>
      <GlobalContextProvider>
        <Head>
          <link rel='shortcut icon' href={pageProps?.icon} />
          <meta
            name='description'
            content={`${pageProps?.name} food delivery &amp; takeaway.`}
          />
          <meta
            property='og:description'
            content={`${pageProps?.name} food delivery &amp; takeaway.`}
          />
          <meta
            name='twitter:description'
            content={`${pageProps?.name} food delivery &amp; takeaway.`}
          />
          <meta
            data-n-head='ssr'
            data-hid='twitterCard'
            name='twitter:card'
            content='summary_large_image'
          />
          <meta
            data-n-head='ssr'
            data-hid='twitterImage'
            name='twitter:image:src'
            content={pageProps?.brand_image}
          />
          <meta
            data-n-head='ssr'
            name='twitter:site'
            content={`@${pageProps?.twitter}`}
          />
          <meta data-n-head='ssr' name='twitter:app:country' content='NG' />
          <meta property='og:url' content={`https://${pageProps?.url}`} />
          <meta property='og:image' content={pageProps?.brand_image} />

          <link rel='apple-touch-icon' sizes='180x180' href={pageProps?.icon} />
          <link
            rel='android-touch-icon'
            sizes='180x180'
            href={pageProps?.icon}
          />
          <link
            rel='icon'
            type='image/png'
            sizes='32x32'
            href={pageProps?.icon}
          />
          <link
            rel='icon'
            type='image/png'
            sizes='16x16'
            href={pageProps?.icon}
          />
          <meta name='theme-color' content={pageProps?.color} />
        </Head>
        <DefaultSeo {...defaultSeo} />
        <Toaster
          position='top-right'
          reverseOrder={false}
          toastOptions={{
            style: {
              zIndex: 101,
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
              padding: 16,
              paddingLeft: 19,
              maxWidth: "100%",
              fontSize: "14px",
              fontWeight: "bold"
            },
            error: {
              duration: 7000,
              style: {
                backgroundColor: "#C83130"
              },
              icon: <ErrorIcon />
            },
            success: {
              style: {
                backgroundColor: "#212642"
              },
              icon: <SuccessIcon />
            },
            loading: {
              style: {
                backgroundColor: "#212642"
              }
            }
          }}
        />
        <NextNProgress
          color={pageProps?.color}
          startPosition={0.3}
          stopDelayMs={200}
          height={4}
          showOnShallow={true}
        />
        <div className={styles.main}>
          <NavBar
            alt={pageProps.alt}
            logo={pageProps.logo}
            color={pageProps.color}
          />
          <Component {...pageProps} />
        </div>
        <Footer
          name={pageProps?.name}
          color={pageProps?.color}
          foreground={pageProps?.foreground}
          phone_number={pageProps?.phone_number}
          twitter={pageProps?.twitter}
          instagram={pageProps?.instagram}
          vendor_id={pageProps?.vendor_id}
        />
      </GlobalContextProvider>
    </PusherProvider>
  );
};

export default MyApp;
