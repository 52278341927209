import { AxiosRequestConfig } from "axios";
import api from "./axios";

export const getUserDetails = async (token?: string) => {
  let config: AxiosRequestConfig | undefined;

  if (token) {
    config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
  }
  const { data } = await api.get(`customer-service-proxy/`, config);
  console.log(data);
  return data;
};

export const getUserOrders = async (
  page: number = 1,
  subdomain: string,
  token?: string
) => {
  let config: AxiosRequestConfig | undefined;

  if (token) {
    config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
  }
  const { data } = await api.get(
    `customer-service-proxy/order?page=${page}&subdomain=${subdomain}`,
    config
  );
  return data;
};

export const getSingleOrder = async (
  order_id: string,
  subdomain: string,
  token?: string
) => {
  let config: AxiosRequestConfig | undefined;

  if (token) {
    config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
  }
  const { data } = await api.get(
    `customer-service-proxy/order/${order_id}?subdomain=${subdomain}`,
    config
  );
  return data;
};

export const getOrderStatus = async (
  order_id: string,
  subdomain: string,
  token?: string
) => {
  let config: AxiosRequestConfig | undefined;

  if (token) {
    config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
  }
  const { data } = await api.get(
    `customer-service-proxy/order/${order_id}/status?subdomain=${subdomain}`,
    config
  );
  return data;
};

export const postOrderReview = async (
  order_id: string,
  subdomain: string,
  payload: {
    restaurant_rating?: number;
    delivery_rating?: number;
    restaurant_comment?: string;
    delivery_comment?: string;
  }
) => {
  const { data } = await api.post(
    `customer-service-proxy/order/${order_id}/review?subdomain=${subdomain}`,
    payload
  );
  return data;
};

export const confirmAbegPayment = async (
  order_id: string,
  subdomain: string,
  token?: string
) => {
  let config: AxiosRequestConfig | undefined;

  if (token) {
    config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
  }
  const { data } = await api.get(
    `customer-service-proxy/order/${order_id}/abeg?subdomain=${subdomain}`,
    config
  );
  return data;
};

export const getUserCards = async (token?: string) => {
  let config: AxiosRequestConfig | undefined;

  if (token) {
    config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
  }
  const { data } = await api.get(`customer-service-proxy/card`, config);
  return data;
};

export const deleteCard = async (card_id: string) => {
  let config: AxiosRequestConfig | undefined;

  const { data } = await api.delete(`customer-service-proxy/card/${card_id}`);
  return data;
};

export const getGiftCardBalance = async (code: string, vendorId: string) => {
  const { data } = await api.get(
    `customer-service-proxy/gift-card/${code}/balance?vendor_id=${vendorId}`
  );
  return data;
};

// Bank Transfers

export const getPaymentDetails = async (orderId: string) => {
  const { data } = await api.get(`customer-service-proxy/order/${orderId}/bank-transfer`);
  return data;
};

export const getPaymentStatus = async (orderId: string) => {
  const { data } = await api.get(
    `customer-service-proxy/order/${orderId}/bank-transfer/status`
  );
  return data;
};
