import { getCartData } from "api/cart";
import { getUserCards, getUserDetails, getUserOrders } from "api/customer";
import { getMealsData } from "api/meals";
import { fetchTransactionsData, getWalletData } from "api/wallet";
import { ModalOptionProps } from "components/UI/organisms/GiftCheckoutForm/types";
import Cookies from "js-cookie";
import { ICart } from "pages/meals";
import {
  createContext,
  useMemo,
  FC,
  useContext,
  useState,
  useCallback,
  useEffect,
  SetStateAction,
  Dispatch
} from "react";
import { formatCurrency } from "utils/formatter";
import { getSubdomain, isClient } from "../utils/utils";

export interface PageMeta {
  current_page: number;
  first_page: number;
  first_page_url: string;
  last_page: number;
  last_page_url: string;
  next_page_url: string;
  per_page: number;
  previous_page_url: string;
  total: number;
}
export interface WebsiteData {
  color: string;
  logo: string;
  phone_number: string;
  instagram: string;
  twitter: string;
  name: string;
}
export interface BankCard {
  id: string;
  bank: string;
  last4: string;
  expiry_date: string;
  brand_image: string;
}

export interface IGiftCard {
  amount: string;
  name: string;
  recipient: string;
  recipient_name: string;
  uuid: number;
}

interface WalletData {
  abeg_code: string;
  account_name: string;
  account_number: string;
  balance: string;
  bank_name: string;
  component_title: string;
  enabled: boolean;
  fund_abeg_description: string;
  fund_bank_description: string;
  fund_text: string;
  page_title: string;
  set_withdrawal_button_text: string;
  setup_description: string;
  withdraw_text: string;
  withdrawal_details: {
    account_name: string;
    account_number: string;
    bank_name: string;
  };
}

export interface Transaction {
  amount: string;
  date: string;
  description: string;
  icon: string;
  modal: {
    icon: string;
    status: string;
    title: string;
    details: {
      can_copy: boolean;
      label: string;
      value: string;
    }[];
  };
  status: string;
  time: string;
  type: string;
  uuid: string;
}

export interface User {
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  picture: string;
  uuid: string;
}

interface ISurchargesData {
  uuid: string;
  name: string;
  formatted_value: string;
  value: number;
  rate: number;
  taxed: boolean;
}
interface IDiscountsData {
  uuid: string;
  name: string;
  formatted_value: string;
  value: number;
  rate: number;
  discount_total: boolean;
}

export interface Prices {
  deliveryFee?: string;
  couponAmount?: string;
  consumerTax?: string | null;
  giftAmount?: string;
  loyalty?: string;
  rawConsumerTax?: number;
  rawDeliveryFeeCharges?: number;
  rawSubtotal: number;
  rawCouponAmount?: number;
  rawGiftAmount?: number;
  rawLoyalty?: number;
  surcharges?: ISurchargesData[];
  discounts?: IDiscountsData[];
}

interface KeyValue {
  key: string;
  value: string;
}

interface PaymentMethods extends KeyValue {
  modals?: ModalOptionProps[];
}

export interface IGiftCardCheckoutDetails {
  payment_gateway_fee_amount: number;
  payment_gateway_fee_percentage: number;
  minimum_amount: number;
  payment_methods: PaymentMethods[];
}

interface ILoyaltyInfo {
  redeemable_amount: string;
  redeemed_amount: string;
}
interface ILoyaltyRecord {
  bottom: string;
  code: string;
  right: string;
  top: string;
  uid: string;
}

interface GlobalState {
  isLoggedIn?: boolean;
  setIsLoggedIn?: any;
  meals?: any;
  setMeals?: any;
  fetchMeals?: any;
  cart?: any;
  setCart?: any;
  fetchCart: any;
  setCards: Dispatch<SetStateAction<BankCard[] | undefined>>;
  cards: BankCard[];
  fetchCards: () => Promise<void>;
  setWallet: Dispatch<SetStateAction<WalletData | undefined>>;
  wallet: WalletData;
  fetchWallet: () => Promise<void>;
  setTransactions: Dispatch<SetStateAction<Transaction[] | undefined>>;
  transactions: Transaction[];
  fetchTransactions: () => Promise<void>;
  user?: User;
  setUser: Dispatch<SetStateAction<User | undefined>>;
  fetchUser: () => Promise<void>;
  setWebsiteData: (data: WebsiteData) => void;
  websiteData: WebsiteData;
  cartPrices: Prices;
  cartTotal: number;
  setCartPrices: (data: Prices) => void;
  vendorAvailability: boolean;
  setVendorAvailability: Dispatch<SetStateAction<boolean | undefined>>;
  setGiftCards: Dispatch<SetStateAction<IGiftCard[] | undefined>>;
  giftCards: IGiftCard[];
  checkoutDetails: IGiftCardCheckoutDetails;
  setCheckoutDetails: Dispatch<
    SetStateAction<IGiftCardCheckoutDetails | undefined>
  >;
  vendorId: string;
  setVendorId: Dispatch<SetStateAction<string>>;
}

const GlobalContext = createContext<GlobalState | any>({
  isLoggedIn: undefined
});

export const GlobalContextProvider: FC = ({ children }) => {
  const [meals, setMeals] = useState([]);
  const [cart, setCart] = useState<ICart | null>(null);
  const [user, setUser] = useState(null);
  const [orders, setOrders] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>();
  const [cards, setCards] = useState<BankCard[]>();
  const [giftCards, setGiftCards] = useState<IGiftCard>();
  const [wallet, setWallet] = useState<WalletData>();
  const [transactions, setTransactions] = useState();
  const [websiteData, setWebsiteData] = useState<WebsiteData | undefined>(
    undefined
  );
  const [cartPrices, setCartPrices] = useState<Prices>();
  const [cartTotal, setCartTotal] = useState<number>(0);
  const [vendorAvailability, setVendorAvailability] = useState(true);
  const [
    checkoutDetails,
    setCheckoutDetails
  ] = useState<IGiftCardCheckoutDetails>();
  const [vendorId, setVendorId] = useState("");

  const token = useMemo(() => Cookies.get("token"), []);

  useEffect(() => {
    if (cartPrices) {
      let subTotal = cartPrices.rawSubtotal;

      let total = subTotal;

      if (cartPrices.rawConsumerTax) {
        total += cartPrices.rawConsumerTax;
      }

      if (cartPrices.rawDeliveryFeeCharges) {
        total = cartPrices.rawDeliveryFeeCharges + total;
      }

      if (cartPrices.rawCouponAmount) {
        total = total - cartPrices.rawCouponAmount;
      }

      if (cartPrices.rawGiftAmount) {
        total = total - cartPrices.rawGiftAmount;
      }

      if (cartPrices.rawLoyalty) {
        total = total - cartPrices.rawLoyalty;
      }

      cartPrices.surcharges?.forEach((surcharge) => {
        total += surcharge.value;
      });

      cartPrices.discounts?.forEach((discount) => {
        total -= discount.value;
      });

      if (total / 100 < 0) {
        setCartTotal(0);
      } else {
        setCartTotal(Math.ceil(total));
      }
    }
  }, [cartPrices]);

  const fetchMeals = useCallback(async () => {
    const subdomain = getSubdomain(window.location.hostname);
    let searchParams = new URLSearchParams(window.location.search);
    const location_id = searchParams.get("location") || "";
    const vendor_branch_id = searchParams.get("vendor_branch_id") || "";

    if (isClient && subdomain && (location_id || vendor_branch_id)) {
      let payload = {
        cartId: Cookies.get("cart") || "",
        subdomain
      };
      if (location_id) {
        // @ts-ignore
        payload.location_id = location_id;
      }

      if (vendor_branch_id) {
        // @ts-ignore
        payload.vendor_branch_id = vendor_branch_id;
      }

      const data = await getMealsData(payload);
      setMeals(data.data);
    }
  }, []);

  const fetchCart = useCallback(async () => {
    let searchParams = new URLSearchParams(window.location.search);
    const location_id = searchParams.get("location") || "";
    const vendor_branch_id = searchParams.get("vendor_branch_id") || "";

    const payload = {
      cartId: Cookies.get("cart") || ""
    };
    if (location_id) {
      // @ts-ignore
      payload.location_id = location_id;
    }

    if (vendor_branch_id) {
      // @ts-ignore
      payload.vendor_branch_id = vendor_branch_id;
    }
    if (payload.cartId.length > 0) {
      try {
        const cart = await getCartData(payload);
        setCart(cart);
      } catch (error) {}
    }
  }, []);

  const fetchUser = useCallback(async () => {
    if (token) {
      const data = await getUserDetails();
      setUser(data.data);
    }
  }, [token]);

  const fetchOrders = useCallback(async () => {
    const subdomain = getSubdomain(window.location.hostname);

    if (token) {
      const data = await getUserOrders(1, subdomain);
      setOrders(data.data);
    }
  }, [token]);

  const fetchCards = useCallback(async () => {
    if (token) {
      const data = await getUserCards();
      setCards(data.data.cards);
    }
  }, [token]);

  const fetchWallet = useCallback(async () => {
    if (token) {
      const data = await getWalletData();
      setWallet(data.data);
    }
  }, [token]);

  const fetchTransactions = useCallback(async () => {
    if (token) {
      const data = await fetchTransactionsData();
      setTransactions(data.data);
    }
  }, [token]);

  useEffect(() => {
    fetchMeals();
    fetchCart();
    fetchUser();
    fetchOrders();
    setIsLoggedIn(!!token);
  }, [fetchCart, fetchMeals, fetchUser, fetchOrders, fetchCards, token]);

  return (
    <GlobalContext.Provider
      value={{
        isLoggedIn: isLoggedIn,
        setIsLoggedIn,
        meals,
        setMeals,
        fetchMeals,
        cart,
        setCart,
        fetchCart,
        user,
        setUser,
        fetchUser,
        orders,
        setOrders,
        fetchOrders,
        setWebsiteData,
        websiteData,
        setCards,
        fetchCards,
        cards,
        setWallet,
        fetchWallet,
        wallet,
        setTransactions,
        transactions,
        fetchTransactions,
        cartPrices,
        cartTotal,
        setCartPrices,
        vendorAvailability,
        setVendorAvailability,
        giftCards,
        setGiftCards,
        checkoutDetails,
        setCheckoutDetails,
        vendorId,
        setVendorId
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalStore = () => {
  return useContext<GlobalState>(GlobalContext);
};
