import { AxiosRequestConfig } from "axios";
import Cookies from "js-cookie";
import { isClient } from "utils/utils";
import api from "./axios";

export const addToCart = async (payload: {
  vendor_id: string;
  vendor_branch_id: string;
  meal_id: string;
  qty: number;
  modifiers: { modifier_id: string; qty: number }[];
}) => {
  const cartId = Cookies.get("cart");
  let response = {};

  if (typeof cartId !== "undefined") {
    const { data } = await api.post(`customer-service-proxy/cart?cartId=${cartId}`, payload);
    Cookies.set("cart", data.data.cart_id);
    response = data;
  } else {
    const { data } = await api.post(`customer-service-proxy/cart`, payload);
    Cookies.set("cart", data.data.cart_id);
    response = data;
  }
  return response;
};

export const getCartData = async (payload: {
  location_id?: string;
  vendor_branch_id?: string;
  cartId: string;
}) => {
  const { location_id, cartId, vendor_branch_id } = payload;
  if (vendor_branch_id) {
    const { data } = await api.get(
      `customer-service-proxy/cart?cart_id=${cartId}&vendor_branch_id=${vendor_branch_id}`
    );
    return data.data;
  }
  if (location_id) {
    const { data } = await api.get(
      `customer-service-proxy/cart?cart_id=${cartId}&location_id=${location_id}`
    );
    return data.data;
  }
};

export const increaseCartMeal = async (payload: { meal_id: string }) => {
  const { meal_id } = payload;

  const { data } = await api.post(`customer-service-proxy/cart/meal/${meal_id}/increase`);
  return data.data;
};

export const decreaseCartMeal = async (payload: { meal_id: string }) => {
  const { meal_id } = payload;

  const { data } = await api.post(`customer-service-proxy/cart/meal/${meal_id}/decrease`);
  return data.data;
};

export const deleteCartMeal = async (payload: { meal_id: string }) => {
  const { meal_id } = payload;

  const { data } = await api.delete(`customer-service-proxy/cart/meal/${meal_id}`);
  return data.data;
};

export const getCheckoutDetails = async (payload: {
  cart_id: string;
  location_id?: string;
  token?: string;
  type?: string;
  vendor_branch_id?: string;
}) => {
  const address =
    isClient && JSON.parse(localStorage.getItem("address") || "[]");

  let config: AxiosRequestConfig | undefined;

  if (payload.token) {
    config = {
      headers: {
        Authorization: `Bearer ${payload.token}`
      }
    };
  }
  const { cart_id, location_id, type, vendor_branch_id } = payload;

  if (location_id) {
    const { data } = await api.get(
      `customer-service-proxy/cart/checkout/details?cart_id=${cart_id}&location_id=${location_id}${
        type ? "&type=" + type : ""
      }`,
      config
    );

    return data.data;
  }

  if (vendor_branch_id && type === "delivery") {
    const { data } = await api.get(
      `customer-service-proxy/cart/checkout/details?cart_id=${cart_id}&address=${
        address[0].label
      }&vendor_branch_id=${vendor_branch_id}${type ? "&type=" + type : ""}`,
      config
    );

    return data.data;
  } else {
    const { data } = await api.get(
      `customer-service-proxy/cart/checkout/details?cart_id=${cart_id}&vendor_branch_id=${vendor_branch_id}${
        type ? "&type=" + type : ""
      }`,
      config
    );

    return data.data;
  }
};

export const verifyCoupon = async (payload: {
  code: string;
  vendor_id: string;
  vendor_branch_id: string;
  cart_id: string;
  location_id: string;
  token?: string;
}) => {
  let config: AxiosRequestConfig | undefined;

  if (payload.token) {
    config = {
      headers: {
        Authorization: `Bearer ${payload.token}`
      }
    };
  }
  const { cart_id, location_id, vendor_branch_id, vendor_id, code } = payload;
  const { data } = await api.get(
    `customer-service-proxy/coupon/${code}?cart_id=${cart_id}&location_id=${location_id}&vendor_id=${vendor_id}&vendor_branch_id=${vendor_branch_id}`,
    config
  );

  return data;
};

export const placeOrder = async (payload: {
  vendor_id: string;
  vendor_branch_id: string;
  cart_id: string;
  first_name: string;
  last_name: string;
  phone: string;
  address?: string;
  landmark?: string;
  delivery_area: string;
  delivery_date?: string;
  delivery_instruction?: string;
  delivery_time?: string;
  pickup_time?: string;
  payment_method: string;
  place_id?: string;
}) => {
  const { data } = await api.post(`customer-service-proxy/cart/checkout`, payload);
  return data.data;
};
