import styles from "./index.module.scss";
import { useState } from "react";
import { classList } from "dynamic-class-list";

interface InputFormProps {
  label?: string;
  id: string;
  maxLength?: number;
  required?: boolean;
  disabled?: boolean;
  type?: "password" | string;
  placeholder?: string;
  errorText?: string;
  noMargin?: boolean;
  inputClass?: string;
  autoCorrect?: string;
  autoComplete?: string;
  textArea?: boolean;
  rows?: number;
  cols?: number;
  inputMode?:
    | "text"
    | "none"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal"
    | "search"
    | undefined;
  value?: string | number | readonly string[] | undefined;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  children?: React.DetailedHTMLProps<React.HTMLAttributes<any>, any>;
  prefix?: React.DetailedHTMLProps<React.HTMLAttributes<any>, any>;
  className?: string | string[];
}

const InputForm = ({
  placeholder,
  id,
  label,
  errorText,
  prefix,
  textArea,
  rows,
  cols,
  noMargin = false,
  children,
  type = "text",
  inputClass = "",
  className = [],
  autoComplete,
  autoCorrect,
  ...props
}: InputFormProps) => {
  className = typeof className === "string" ? [className] : className;

  const [inputType, setInputType] = useState(type);
  const [togglePasswordText, setTogglePasswordText] = useState<"Show" | "Hide">(
    "Show"
  );

  let errorClass = null;
  let noMarginClass = null;
  let prefixClass = null;

  if (errorText && errorText?.length > 0) {
    errorClass = styles["input--error"];
  }

  if (noMargin) {
    noMarginClass = styles["form_control--no-margin"];
  }

  if (prefix) {
    prefixClass = styles["input--prefix"];
  }

  const togglePassword = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();

    if (togglePasswordText === "Show") {
      setTogglePasswordText("Hide");
      setInputType("text");
    } else {
      setTogglePasswordText("Show");
      setInputType("password");
    }
  };

  return (
    <div
      className={classList([styles.form_control, noMarginClass, ...className])}
    >
      {label && (
        <label className={classList([styles.label])} htmlFor={id}>
          {label}
        </label>
      )}
      <div className={styles.input_holder}>
        {prefix && <div className={styles.prefix}>{prefix}</div>}
        {!textArea ? (
          <input
            id={id}
            className={classList([
              styles.input,
              errorClass,
              prefixClass,
              inputClass
            ])}
            type={inputType}
            placeholder={placeholder}
            autoComplete={autoComplete}
            autoCorrect={autoCorrect}
            onKeyUp={(e) => {
              if (e.code === "Space") {
                return;
              }
            }}
            {...props}
          />
        ) : (
          <textarea
            rows={rows}
            cols={cols}
            placeholder={placeholder}
            className={classList([
              styles.input,
              errorClass,
              prefixClass,
              inputClass,
              styles["text-area"]
            ])}
            {...props as any}
          ></textarea>
        )}
        {type === "password" && (
          <a
            href='/toggle'
            onClick={togglePassword}
            className={styles.password_toggle}
          >
            {togglePasswordText}
          </a>
        )}
      </div>
      {errorText && (
        <span className={classList([styles.error_message])}>{errorText}</span>
      )}
      {children}
    </div>
  );
};

export default InputForm;
