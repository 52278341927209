import Image from "components/UI/atoms/Image";
import styles from "./index.module.scss";

import icon from "../../../../assets/svgs/icons/button-arrow.svg";
import downArrow from "../../../../assets/svgs/icons/down-arrow.svg";

export interface ButtonProps {
  size?: "small" | "medium" | "normal";
  color?: string;
  type?: "normal" | "rounded";
  label: string | React.DetailedHTMLProps<React.HTMLAttributes<any>, any>;
  fullWidth?: boolean;
  disabled?: boolean;
  forwardIcon?: boolean;
  downwardIcon?: boolean;
  iconIsBeforeLabel?: boolean;
  otherIcon?: boolean;
  iconSrc?: string | any;
  className?: string | undefined;
  style?: React.CSSProperties | undefined;
  submit?: boolean;
  onClick?: () => void;
}

const Button = ({
  size = "normal",
  type = "normal",
  disabled = false,
  forwardIcon = false,
  downwardIcon = false,
  fullWidth = false,
  otherIcon = false,
  iconIsBeforeLabel = false,
  color = "dark",
  label,
  iconSrc,
  className = "",
  submit = true,
  ...props
}: ButtonProps) => {
  let sizeClass = null;
  let disabledClass = null;
  let colorClass = null;
  let fullWidthClass = null;
  let typeClass = null;

  // set size class
  if (size !== "normal") {
    sizeClass = styles[`button--${size}`];
  }

  // set disabled class
  if (disabled) {
    disabledClass = styles["button--disabled"];
  }

  // set color class
  if (color !== "dark") {
    colorClass = styles[`button--${color}`];
  }

  if (fullWidth) {
    fullWidthClass = styles["button--full"];
  }

  // set type class
  if (type !== "normal") {
    typeClass = styles[`button--${type}`];
  }

  return (
    <button
      className={`
        ${styles.button}
        ${sizeClass}
        ${disabledClass}
        ${colorClass}
        ${fullWidthClass}
        ${typeClass}
        ${className}
      `}
      type={submit ? "submit" : "button"}
      {...props}
      disabled={disabled}
    >
      {otherIcon &&
        iconIsBeforeLabel &&
        iconSrc &&
        (typeof iconSrc === "string" ? (
          <Image src={iconSrc} alt="" layout="fill" />
        ) : (
          iconSrc
        ))}
      {label}
      {forwardIcon && <Image src={icon} alt="" className={styles.icon} />}
      {downwardIcon && <Image src={downArrow} alt="" />}
      {otherIcon &&
        !iconIsBeforeLabel &&
        iconSrc &&
        (typeof iconSrc === "string" ? (
          <Image src={iconSrc} alt="" layout="fill" />
        ) : (
          iconSrc
        ))}
    </button>
  );
};

export default Button;
