import api from "./axios";

export const getMealsPageData = async (
  subdomain: string,
  location_id: string,
  vendor_branch_id: string,
  type?: string
) => {
  if (location_id) {
    const { data } = await api.get(
      `customer-service-proxy/website/${subdomain}/meal-page/${location_id}`
    );
    return data;
  }
  if (vendor_branch_id) {
    const { data } = await api.get(
      `customer-service-proxy/website/${subdomain}/meal-page?vendor_branch_id=${vendor_branch_id}&type=${
        type || ""
      }`
    );
    return data;
  }
};

export const getMealsData = async (payload: {
  subdomain: string;
  location_id?: string;
  cartId: string;
  vendor_branch_id?: string;
}) => {
  const { subdomain, location_id, cartId, vendor_branch_id } = payload;

  if (location_id) {
    const { data } = await api.get(
      `customer-service-proxy/website/${subdomain}/meals/${location_id}?cartId=${cartId}`
    );
    return data;
  } else {
    const { data } = await api.get(
      `customer-service-proxy/website/${subdomain}/meals?vendor_branch_id=${vendor_branch_id}&cartId=${cartId}`
    );
    return data;
  }
};

export const getMeal = async (meal_id: string) => {
  const { data } = await api.get(`customer-service-proxy/meals/${meal_id}`);
  return data.data;
};
