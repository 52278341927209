import Button from "../../atoms/Button";
import Modal from "../../molecules/Modal";
import styles from "./index.module.scss";
import InputForm from "../../molecules/InputForm";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { authenticateUser, verifyPin } from "api/auth";
import { saveToken } from "utils/token";
import { useGlobalStore } from "context/GlobalContext";
import { AxiosError } from "axios";
import showErrorToast from "utils/toast";
import Loading from "components/UI/atoms/Loading";
import ReCAPTCHA from "react-google-recaptcha";

interface AuthModalProps {
  showAuthModal: boolean;
  onClose: () => void;
  color: string;
  showOtp?: boolean;
  propPhoneNumber?: string;
}

const AuthModal = ({
  showAuthModal,
  onClose,
  color,
  propPhoneNumber,
  showOtp = false
}: AuthModalProps) => {
  const id = useRef<any>();
  const recaptchaRef: any = React.createRef();
  const recaptchaRef2: any = React.createRef();
  const { setIsLoggedIn, fetchUser } = useGlobalStore();
  const [phone, setPhone] = useState(propPhoneNumber ?? "");
  const [pin, setPin] = useState("");
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [timedOut, setTimedOut] = useState(false);
  const [otpTimer, setOtpTimer] = useState("01:00");
  const [errorText, setErrorText] = useState("");

  const clearTimer = () => {
    clearInterval(id.current);
    setOtpTimer("01:00");
  };

  useEffect(() => {
    if (showOtp) {
      setOtpSent(true);
      clearTimer();
      handleTimer();
    } else {
      clearTimer();
      setOtpSent(false);
      setPhone("");
      setTimedOut(false);
    }
    setErrorText("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose, showOtp]);

  useEffect(() => {
    if (propPhoneNumber) {
      setPhone(propPhoneNumber);
    }
  }, [propPhoneNumber]);

  const handleTimer = () => {
    let minutes, seconds;
    let s_timer = 60;
    id.current = setInterval(() => {
      minutes = Math.floor(s_timer / 60);
      seconds = s_timer % 60;

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      setOtpTimer(minutes + ":" + seconds);

      s_timer = s_timer - 1;

      if (s_timer < 0) {
        clearTimer();
        setTimedOut(true);
      }
    }, 1000);
  };

  const handleSubmit = useCallback(
    () => {
      recaptchaRef.current.execute();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [recaptchaRef]
  );

  const handlePhoneNumberSubmit = useCallback(
    async (captchaCode) => {
      if (!captchaCode) {
        return;
      }
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
      setLoading(true);
      try {
        await authenticateUser(phone, captchaCode);
        setOtpSent(true);
        handleTimer();
      } catch (err) {
        const error = err as AxiosError;
        showErrorToast(error);
      }
      setLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [phone, recaptchaRef]
  );

  const handleOtpSubmit = useCallback(
    async (pin) => {
      setErrorText("");
      setLoading(true);
      try {
        const { status, data } = await verifyPin(phone, pin);
        if (status) {
          await saveToken(data.token);
          setIsLoggedIn(true);
          clearTimer();
          fetchUser();
          onClose();
        }
      } catch (error: any) {
        if (error?.response?.data?.message) {
          setErrorText(error.response?.data?.message);
        } else {
          setErrorText("Something went wrong, please try again");
        }
      }
      setLoading(false);
    },
    [phone, fetchUser, onClose, setIsLoggedIn]
  );

  return (
    <Modal
      className={styles.modal}
      showModal={showAuthModal}
      onClose={onClose}
      hideOverflow={true}
    >
      <div className={styles.body}>
        <div className={styles.form}>
          <div className={styles.login}>
            <h2 className='mb-4'>
              {propPhoneNumber ? "Confirm Phone Number" : "Log in"}
            </h2>
            {!otpSent ? (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <InputForm
                  id='phone-number'
                  label='Phone Number'
                  placeholder='08012345678'
                  maxLength={11}
                  type='tel'
                  inputMode='tel'
                  required
                  onChange={({ target }) => setPhone(target.value)}
                />

                <ReCAPTCHA
                  ref={recaptchaRef}
                  size='invisible'
                  sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY!}
                  onChange={handlePhoneNumberSubmit}
                />

                <p className={styles.disclaimer}>
                  If you sign up,{" "}
                  <a href='#'>Terms & Conditions and Privacy policy</a> apply
                </p>
                <Button
                  label='Continue'
                  type='rounded'
                  fullWidth
                  className={styles.loginButton}
                  disabled={loading}
                />
              </form>
            ) : (
              <div className='d-flex align-items-center flex-column'>
                <div className={styles["pin-code"]}>
                  <p>We sent verification code to</p>
                  <div className={styles.bottom}>
                    <p>{phone ?? propPhoneNumber}</p>
                    <Button
                      style={{ color }}
                      className={styles["change-button"]}
                      color='transparent'
                      size='small'
                      onClick={() => {
                        setOtpSent(false);
                        setTimedOut(false);
                        setErrorText("");
                        clearTimer();
                      }}
                      label='Change'
                    />
                  </div>
                </div>

                <div className='align-self-stretch'>
                  <InputForm
                    id='pin'
                    label=''
                    placeholder='123456'
                    maxLength={6}
                    type='text'
                    inputMode='numeric'
                    required
                    onChange={({ target }) => setPin(target.value)}
                  />
                </div>

                {errorText && <p className={styles.error}>{errorText}</p>}
                {loading ? (
                  <div className='mt-3'>
                    <Loading />
                  </div>
                ) : (
                  <div className=''>
                    <Button
                      label='Continue'
                      type='rounded'
                      onClick={() => handleOtpSubmit(pin)}
                      fullWidth
                      className={styles.loginButton}
                      disabled={loading || !pin.length}
                    />
                    <ReCAPTCHA
                      ref={recaptchaRef2}
                      size='invisible'
                      sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY!}
                      onChange={handlePhoneNumberSubmit}
                    />
                    {timedOut ? (
                      <p className='mt-3'>
                        Didn’t get OTP?
                        <a
                          href='#'
                          onClick={(e) => {
                            e.preventDefault;
                            setTimedOut(false);
                            setErrorText("");
                            clearTimer();
                            recaptchaRef2.current.execute();
                          }}
                          style={{ color }}
                          className={styles.resend}
                        >
                          Resend
                        </a>
                      </p>
                    ) : (
                      <p className={styles["resend-otp"]}>
                        Didn’t get OTP? Resend in{" "}
                        <span style={{ color }}>{otpTimer}</span>
                      </p>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AuthModal;
