/* eslint-disable @next/next/no-img-element */
import { FC } from "react";
import Image from "components/UI/atoms/Image";
import Link from "next/link";
import styles from "./index.module.scss";
import { useRouter } from "next/router";
import Button from "../../atoms/Button";
import { useState, useEffect } from "react";
import PersonIcon from "assets/svgs/icons/person.svg";
import Dropdown from "../Dropdown";
import { useGlobalStore } from "../../../../context/GlobalContext";
import AuthModal from "components/UI/organisms/AuthModal";
import { getUserDetails } from "api/customer";
import DownArrow from "assets/svgs/icons/down-arrow.svg";
import Cookies from "js-cookie";
import { useClickOutsideListenerRef } from "utils/useClickOutsideListenerRef";
import toast from "react-hot-toast";

interface Props {
  logo: string;
  color: string;
  alt?: boolean;
}

const NavBar: FC<Props> = ({ logo, color, alt = false }) => {
  const router = useRouter();
  const ref = useClickOutsideListenerRef(() => {
    setShowDropdown(false);
  });

  const { isLoggedIn, user, setUser, setIsLoggedIn } = useGlobalStore();
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    if (isLoggedIn && !user) {
      (async function () {
        const res = await getUserDetails();

        setUser(res.data);
      })();
    }
  }, [isLoggedIn, setUser, user]);

  return (
    <nav className={`${styles.nav} ${alt ? styles.alt : ""}`}>
      <div className={`${styles.container} container`}>
        <Link href='/'>
          <a>
            {logo && <img src={logo} alt='Logo' className={styles.image} />}
          </a>
        </Link>

        {!alt && (
          <div className=''>
            {!isLoggedIn ? (
              <Button
                onClick={() => {
                  setShowAuthModal(true);
                }}
                label='Login'
                size='medium'
                color='bordered-dark'
                type='rounded'
              />
            ) : (
              <div className={styles.user}>
                <div className={styles["user-button"]}>
                  <div ref={ref}>
                    <Button
                      onClick={() => {
                        setShowDropdown((prev) => !prev);
                      }}
                      color='transparent'
                      label={
                        <div className={styles["user-label"]}>
                          <div>
                            <p>
                              {user?.first_name
                                ? user?.first_name[0].toUpperCase()
                                : user?.phone_number[3]}
                            </p>
                          </div>

                          <DownArrow />
                        </div>
                      }
                    />
                  </div>
                </div>

                <Dropdown showDropdown={showDropdown}>
                  <ul>
                    <li>
                      <Button
                        label='My Orders'
                        fullWidth
                        color='transparent'
                        onClick={() => {
                          router.push("/orders");
                        }}
                      />
                    </li>

                    <li>
                      <Button
                        label='My Wallet'
                        fullWidth
                        color='transparent'
                        onClick={() => {
                          router.push("/wallet");
                        }}
                      />
                    </li>

                    <li>
                      <Button
                        label='My Cards'
                        fullWidth
                        color='transparent'
                        onClick={() => {
                          router.push("/cards");
                        }}
                      />
                    </li>

                    <li>
                      <Button
                        label='My Gift Cards'
                        fullWidth
                        color='transparent'
                        onClick={() => {
                          router.push("/gift-cards");
                        }}
                      />
                    </li>

                    <li>
                      <Button
                        label='Logout'
                        fullWidth
                        color='transparent'
                        onClick={() => {
                          Cookies.remove("token");
                          localStorage.removeItem("token");
                          setIsLoggedIn(false);
                          setUser(undefined);
                          toast.success("Logged out successfully");
                        }}
                      />
                    </li>
                  </ul>
                </Dropdown>
              </div>
            )}
          </div>
        )}
      </div>

      <AuthModal
        showAuthModal={showAuthModal}
        onClose={() => setShowAuthModal(false)}
        color={color}
      />
    </nav>
  );
};

export default NavBar;
