export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const isClient = typeof window !== "undefined";
export const isServer = typeof window === "undefined";

export const capitalizeFirstLetter = (subdomain: string) =>
  subdomain.charAt(0).toUpperCase() + subdomain.slice(1);

export const stopBodyScroll = () => {
  const body = document.querySelector("body");
  if (body) {
    body.style.overflow = "hidden";
  }
};

export const enableBodyScroll = () => {
  const body = document.querySelector("body");
  if (body) {
    body.style.overflow = "auto";
  }
};

export const generateLocationToken = (): string => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

  let result = " ";
  const currentTime = `${Date.now()}`;
  const charactersLength = characters.length;

  for (let i = 0; i < currentTime.length * 4; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    if (i === currentTime.length) {
      result += currentTime.substring(0, 4);
    }
    if (i === currentTime.length * 2) {
      result += currentTime.substring(4, 8);
    }
    if (i === currentTime.length * 3) {
      result += currentTime.substring(8, currentTime.length);
    }
  }
  return result;
};

export const isValidJSON = (text: string): boolean => {
  try {
    JSON.parse(text);
  } catch (e) {
    return false;
  }
  return true;
};

export const getSubdomain = (host: string): string => {
  if (process.env.NEXT_PUBLIC_SUBDOMAIN) {
    return process.env.NEXT_PUBLIC_SUBDOMAIN;
  } else {
    if (host.includes(".buyfood.app")) {
      return host.split(".")[0];
    } else {
      return host.replace("www.", "");
    }
  }
};
